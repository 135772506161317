<script setup>
const { t } = useI18n();
const metaOptions = {
  title: `${t("page.home.meta.title")} | ART VR`,
  description: t("page.home.meta.description"),
  keywords: t("page.home.meta.keywords"),
};
useMeta(metaOptions);
</script>

<template>
  <div>
    <StickyBg />
    <Intro />
    <Program />
    <News />
    <!-- <Curator /> -->
  </div>
</template>
